
<template>
    <div id="table-demo">
      <div id="survey-table-loading" class="vs-con-loading__container">
        <vx-card  class="">
          <vs-table :data="pageData.data" class="mb-4">
            <template slot="header">
              <div class="md:flex py-2 md:py-4 w-full">
                <div class="sm:flex flex-grow items-center mb-2 md:mb-0">
                  <div class="sm:mr-2 mr-0 mb-2 sm:mb-0">
                    <vs-dropdown vs-trigger-click class="cursor-pointer">
                      <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span v-if="pageData.total" class="mr-2"> {{paginationInfo }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                      </div>
                      <vs-dropdown-menu>
                      <vs-dropdown-item @click="itemsPerPage = 10">
                          <span>10</span>
                      </vs-dropdown-item>
                      <vs-dropdown-item @click="itemsPerPage = 50">
                          <span>50</span>
                      </vs-dropdown-item>
                      <vs-dropdown-item @click="itemsPerPage = 100">
                          <span>100</span>
                      </vs-dropdown-item>
                      <vs-dropdown-item @click="itemsPerPage = 200">
                          <span>200</span>
                      </vs-dropdown-item>
                      </vs-dropdown-menu>
                    </vs-dropdown>
                  </div>
                </div>
                 <vs-input class="sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" 
                v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />
              </div>
            </template>
            <template slot="thead">
              <vs-th>Impression</vs-th>
              <vs-th>Message</vs-th>
              <vs-th>Date</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <span>{{tr.impression | capitalize}}</span>
                </vs-td>
                <vs-td>
                  {{tr.message | truncate(80)}}
                </vs-td>
                <vs-td>
                  {{tr.created_at | date_ago}}
                </vs-td>

                <template slot="expand">
                  <div class="con-expand-users expand-users w-full p-3">
                      <h5>{{tr.impression | capitalize}}</h5>
                      <p>{{tr.message}}</p>
                      <div class="my-5">
                        <span class="text-primary cursor-pointer mr-3" @click="$router.push({path: `/users/${tr.user_id}`})" >View user</span>
                        <span class="text-primary cursor-pointer" @click="$router.push({path: `/orders/${tr.order_id}`})" >View order</span>
                      </div>
                  </div>
                </template>
              </vs-tr>
            </template>
          </vs-table>
          <vs-pagination
            :total="pageData.last_page || 1"
            :max="7"
            v-model="currentPage" />
        </vx-card>
      </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default{
  data() {
    return {
      pageData: {
        data:[]
      },
      searchQuery: "",
    }
  },
  computed:{
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchSurveys(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchSurveys(1, val)
      }
    },
    paginationInfo(){
      return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
      - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
      ? this.currentPage * this.itemsPerPage : this.pageData.total}
       of ${this.pageData.total}`
    }
  },
  methods: {
    fetchSurveys(page, per_page = null) {
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.$vs.loading({ container: '#survey-table-loading', color: "#444", type: "sound"})
      this.$http.get(`/surveys?${new URLSearchParams(payload).toString()}`)
      .then((response) => {
        this.$vs.loading.close('#survey-table-loading > .con-vs-loading')
        if(response.data.success){
          this.pageData = response.data.data;
        }
      })
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchSurveys()
    }, 2000),
  },
  mounted(){
    this.fetchSurveys()
  },
}
</script>

<style type="text/css">
[dir] .vx-card .vx-card__collapsible-content .vx-card__body {
    padding-top: .5rem !important;
}
</style>
